import { ethers } from "ethers";
import { make } from "@/request";
import { markRaw } from "vue";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import contractJson from "@/assets/soth-contract.json";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  namespaced: true,
  state: {
    address: null,
    user: null,
    connection: null,
    checked: false,
  },
  mutations: {
    SET_ADDRESS(state, payload) {
      state.address = payload;
    },
    SET_USER(state, payload) {
      state.checked = true;
      state.user = payload;
    },
    SET_CONNECTION(state, payload) {
      state.connection = {
        signer: markRaw(payload?.signer),
        provider: markRaw(payload?.provider),
        address: payload?.address,
        network: markRaw(payload?.network),
      };
    },
    CLEAR_CONNECTION(state, payload) {
      state.connection = null;
    },
  },
  actions: {
    async clearListeners({ state }) {
      if (state?.connection?.provider) {
        state.connection.provider.provider.removeAllListeners();
      }
      return;
    },
    async startListeners({ commit, dispatch, state }) {
      await dispatch("clearListeners");

      if (state?.connection?.provider) {
        state?.connection?.provider.provider.on("accountsChanged", (accounts) => {
          console.info("account changed: ", accounts);
          dispatch("connect", true);
        });

        // Subscribe to chainId change
        state?.connection?.provider.provider.on("chainChanged", (chainId) => {
          console.info("Chain changed: ", chainId);
          dispatch("connect", true);
        });

        // Subscribe to session disconnection
        state?.connection?.provider.provider.on("disconnect", (code, reason) => {
          console.info("Wallet disconnected: ", code, reason);
          commit("CLEAR_CONNECTION");
        });
      }

      return;
    },
    async connect({ commit, dispatch, state }, listenersOn) {
      await dispatch("clearListeners");

      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "18436e45322646beae413025603f5fe0", // required
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required
      });

      let signer, provider, network;
      try {
        const cleared = await web3Modal.clearCachedProvider();
        const instance = await web3Modal.connect();
        provider = new ethers.providers.Web3Provider(instance);
        signer = await provider.getSigner();
        network = await provider.getNetwork();
      } catch (e) {
        console.info("web3 rejected", { e });
        dispatch(
          "toasts/add",
          { message: "Web3 connection request rejected by user", variant: "error" },
          { root: true }
        );
        commit("CLEAR_CONNECTION");
        return false;
      }

      const address = await signer.getAddress();

      commit("SET_CONNECTION", { signer, provider, address, network });

      if (listenersOn) {
        dispatch("startListeners");
      }

      return { signer, provider, address, network };
    },
    async disconnect({ commit, dispatch }) {
      await dispatch("clearListeners");
      commit("CLEAR_CONNECTION");
      return;
    },
    async mint({ state, dispatch }, qty) {
      // check is public
      // check is not closed
      // check remaining count of tokens
      // get price
      //
      if (!state.connection?.signer) {
        dispatch(
          "toasts/add",
          { message: "Must connect your wallet to mint", variant: "error" },
          { root: true }
        );
        return;
      }
      if (state?.connection?.network?.chainId !== 1) {
        dispatch(
          "toasts/add",
          { message: "Must be on Ethereum Mainnet", variant: "error" },
          { root: true }
        );
        return;
      }

      let contractInstance = await new ethers.Contract(
        "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
        contractJson.abi,
        state.connection.signer
      );

      let isPublic = await contractInstance.isPublic();
      let isClosed = await contractInstance.isClosed();

      if (!isPublic || isClosed) {
        dispatch("toasts/add", { message: "Mint is not open", variant: "error" }, { root: true });
        return false;
      }

      let nextToken = await contractInstance.nextToken();
      let maxTokens = await contractInstance.maxTokens();

      if (nextToken.add(1).gt(maxTokens)) {
        dispatch(
          "toasts/add",
          { message: "No tokens remaining", variant: "error" },
          { root: true }
        );
        return false;
      }

      let bigNumAmount = await contractInstance.price();
      let valMultiplied = bigNumAmount.mul(qty);
      let valForTransaction = valMultiplied;

      let shopAttempt;
      try {
        shopAttempt = await contractInstance["shop(uint256)"](qty, {
          value: valForTransaction,
        });
      } catch (e) {
        dispatch(
          "toasts/add",
          {
            message: ["Transaction not completed", e?.message, e?.data?.message, e?.error?.message],
            variant: "error",
          },
          { root: true }
        );
        return false;
      }

      let confirmingSoon = dispatch(
        "toasts/add",
        {
          message: "Mint will confirm soon",
          variant: "success",
          timeout: false,
          dismissible: false,
        },
        { root: true }
      );

      let confirmation = await state.connection.provider.waitForTransaction(shopAttempt.hash, true);

      await delay(10000);

      dispatch("toasts/remove", confirmingSoon, { root: true });

      dispatch(
        "toasts/add",
        {
          message: "Mint complete!",
          variant: "success",
        },
        { root: true }
      );

      return true;
      // const sothAddress = "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9";
    },

    async details({ state }) {
      // check is public
      // check is not closed
      // check remaining count of tokens
      // get price
      //
      if (!state.connection?.signer) {
        return false;
      }

      if (state?.connection?.network?.chainId !== 1) {
        return false;
      }

      let contractInstance = await new ethers.Contract(
        "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
        contractJson.abi,
        state.connection.signer
      );

      let isPublic = await contractInstance.isPublic();
      let isClosed = await contractInstance.isClosed();

      let nextToken = await contractInstance.nextToken();
      let maxTokens = await contractInstance.maxTokens();

      let price = await contractInstance.price();

      return {
        isPublic,
        isClosed,
        nextToken: nextToken?.toNumber(),
        maxTokens: maxTokens?.toNumber(),
        price: ethers.utils.formatEther(price?.toString()),
        totalMinted: nextToken?.toNumber() ? nextToken?.toNumber() - 1 : 0,
        maxMintable:
          isPublic && !isClosed && nextToken.add(1).lte(maxTokens)
            ? maxTokens.sub(nextToken) > 10
              ? 10
              : maxTokens.sub(nextToken)
            : null,
      };
    },
  },
  getters: {
    isChecked(state) {
      return !!state?.checked;
    },
    isLoggedIn(state) {
      return !!state?.user;
    },
    tokenOwnedByUser: (state) => (token) => {
      return !!state.user?.wallets.find((w) => {
        return w.wallet_address === token?.owner_address;
      });
    },
  },
};
