import { make } from "@/request";

export default {
  namespaced: true,
  state: {
    day: null,
    game: null,
    showLeaderboard: false,
    showRules: false,
    showStats: false,
    gameResetDisplay: null,
  },
  mutations: {
    SET_DAY(state, data) {
      state.day = data;
      if (data?.current_game) {
        state.game = data.current_game;
      }
    },
    CLEAR_DAY(state, data) {
      state.day = null;
      state.game = null;
    },
    SET_GAME(state, data) {
      state.game = data;
    },
    SET_SHOW_LEADERBOARD(state, data) {
      state.showLeaderboard = data;
    },
    SET_SHOW_RULES(state, data) {
      state.showRules = data;
    },
    SET_GAME_RESET_DISPLAY(state, data) {
      state.gameResetDisplay = data;
    },
    SET_SHOW_STATS(state, data) {
      state.showStats = data;
    },
  },
  actions: {
    clearDay({ commit }) {
      commit("CLEAR_DAY");
    },
    setShowLeaderboard({ commit }, payload) {
      commit("SET_SHOW_LEADERBOARD", payload);
    },
    setShowRules({ commit }, payload) {
      commit("SET_SHOW_RULES", payload);
    },
    setGameResetDisplay({ commit }, payload) {
      commit("SET_GAME_RESET_DISPLAY", payload);
    },
    setShowStats({ commit }, payload) {
      commit("SET_SHOW_STATS", payload);
    },
    async getLeaderboard({ commit }) {
      let request;
      try {
        request = await make({ name: "pokerLeaderboard" });
      } catch (e) {
        return;
      }

      return request;
    },
    async getDay({ commit }) {
      let request;
      try {
        request = await make({ name: "pokerGetDay" });
      } catch (e) {
        commit("CLEAR_DAY");
        return;
      }

      commit("SET_DAY", request);

      return request;
    },
    async createDay({ commit, dispatch }) {
      let request;
      try {
        request = await make({ name: "pokerCreateDay" });
      } catch (e) {
        commit("CLEAR_DAY");
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return;
      }

      commit("SET_DAY", request);

      return request;
    },
    async lockDay({ commit, dispatch }, tokens) {
      let request;
      try {
        request = await make({
          name: "pokerLockDay",
          data: {
            tokens,
          },
        });
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: ["Something went wrong", e], variant: "error" },
          { root: true }
        );
        return;
      }

      commit("SET_DAY", request);

      return request;
    },
    async tokens() {
      const request = await make({ name: "pokerGetTokens" });

      return request;
    },
    async startGame({ commit }) {
      let request;
      try {
        request = await make({ name: "pokerStartGame" });
      } catch (e) {
        return;
      }

      commit("SET_DAY", request);

      return request;
    },
    // async draw({ commit }, cards) {
    //   let request;
    //   try {
    //     request = await make({
    //       name: "pokerGameDraw",
    //       data: {
    //         cards,
    //       },
    //     });
    //   } catch (e) {
    //     return;
    //   }

    //   commit("SET_DAY", request);

    //   return request;
    // },
    // async powerup({ commit }, { cards, powerup }) {
    //   let request;
    //   try {
    //     request = await make({
    //       name: "pokerGamePowerup",
    //       data: {
    //         cards,
    //         powerup,
    //       },
    //     });
    //   } catch (e) {
    //     return;
    //   }

    //   commit("SET_DAY", request.day);

    //   return request;
    // },
  },
  getters: {},
};
