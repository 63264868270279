import { make } from "@/request";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async estimate({ commit }, data) {
      return await make({ name: "buyEstimate", data });
    },
    async checkout({ dispatch }, data) {
      let req;

      try {
        req = await make({ name: "buyCheckout", data });
      } catch (e) {
        dispatch(
          "toasts/add",
          {
            message: e ?? "Something went wrong",
            variant: "error",
          },
          { root: true }
        );
      }

      return req;
    },
    async details({ commit }, checkout_id) {
      return await make({ name: "buyDetails", data: { checkout_id } });
    },
    async orders({ commit }) {
      return await make({ name: "buyOrders" });
    },
  },
};
