import { make } from "@/request";
import ErrorParse from "@/classes/ErrorParse";

export default {
  namespaced: true,
  state: {
    list: [],
    // item: null,
    createEdit: null,
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload ? payload : [];
    },
    SET_SHOW_CREATE_EDIT(state, payload) {
      state.createEdit = payload;
    },
  },
  actions: {
    setShowCreateEdit({ commit }, payload) {
      commit("SET_SHOW_CREATE_EDIT", payload);
    },
    async getList({ commit }) {
      let request;
      try {
        request = await make({ name: "suggestionsGet" });
      } catch (e) {
        console.info("can't get suggestions", { e });
        commit("SET_LIST", null);
        return;
      }

      commit("SET_LIST", request);

      return request;
    },
    async get({ dispatch }, id) {
      let request;

      try {
        request = await make({ name: "suggestionGet", params: { id } });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return;
      }

      return request;
    },
    async make({ dispatch }, data) {
      let request;

      try {
        request = await make({ name: "suggestionMake", data });
        dispatch(
          "toasts/add",
          { message: "Suggestion created!", variant: "success" },
          { root: true }
        );
      } catch (e) {
        dispatch("toasts/add", { message: new ErrorParse(e), variant: "error" }, { root: true });
        return;
      }

      return request;
    },
  },
};
