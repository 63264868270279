import { make } from "@/request";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async redeemChips({ commit }) {
      return await make({ name: "arcadeRedeemChips" });
    },
    async redeemPoints({ commit }) {
      return await make({ name: "arcadeRedeemPoints" });
    },
    async redeemMedallions({ commit }) {
      return await make({ name: "arcadeRedeemMedallions" });
    },
    async portalInfo({ commit }) {
      return await make({ name: "portalInfo" });
    },
    async portalRedemptionCredit({ dispatch }, data) {
      let request;
      try {
        request = await make({ name: "portalRedemptionCredit", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      dispatch(
        "toasts/add",
        { message: "Portal expedition funded!", variant: "success" },
        { root: true }
      );

      return true;
    },
    async dailyTrivia({ dispatch }) {
      let request;
      try {
        request = await make({ name: "arcadeTriviaDaily" });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      // dispatch(
      //   "toasts/add",
      //   { message: "Portal expedition funded!", variant: "success" },
      //   { root: true }
      // );

      return request;
    },

    async arcadeTriviaDailyAnswer({ dispatch }, data) {
      let request;
      try {
        request = await make({ name: "arcadeTriviaDailyAnswer", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      // dispatch(
      //   "toasts/add",
      //   { message: "Portal expedition funded!", variant: "success" },
      //   { root: true }
      // );

      return request;
    },
    async arcadeTriviaDailyReport({ dispatch }, data) {
      let request;
      try {
        request = await make({ name: "arcadeTriviaDailyReport", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      console.log({ request });

      dispatch(
        "toasts/add",
        { message: request, variant: "success", timeout: 5000 },
        { root: true }
      );

      return true;
    },
    async arcadeMemoryTokens() {
      return await make({ name: "arcadeMemoryTokens" });
    },
    async arcadeMemoryDaily(context, data) {
      return await make({ name: "arcadeMemoryDaily", data });
    },
  },
};
