const defaultTransform = (data) => data;

class RequestGroupDefinition {
  definitions;

  transformResponse;

  constructor({ definitions, transformResponse = null }) {
    this.definitions = definitions;
    this.transformResponse = transformResponse || defaultTransform;
  }
}

export default RequestGroupDefinition;
