const defaultTransformResponse = (response) => {
  return response?.data ?? response;
};
const defaultTransformRequest = (data) => data;
const defaultTransformParams = (params) => params;
const defaultTransformError = (error) => error?.response?.data?.errors ?? error;

class RequestDefinition {
  constructor(data) {
    this.path = data.path;
    this.method = data.method || "get";
    this.headers = data.headers || {};
    this.options = data.options || {};
    this.transformParams = data.transformParams || defaultTransformParams;
    this.transformRequest = data.transformRequest || defaultTransformRequest;
    this.transformResponse = data.transformResponse || defaultTransformResponse;
    this.transformError = data.transformError || defaultTransformError;
  }
}

export default RequestDefinition;
