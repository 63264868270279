<script setup>
import { computed, ref } from "vue";
import store from "@/store";
import { load } from "mime";

const user = computed(() => {
  return store.state.auth.user;
});

const loading = ref(false);

const logout = async () => {
  loading.value = true;
  await store.dispatch("auth/logout");
  loading.value = false;
};

const acceptTerms = async () => {
  loading.value = true;
  await store.dispatch("auth/acceptTerms");
  loading.value = false;
};
// const props = defineProps({
//   max: String,
// });

// if they accept, user data will update and modal will disappear
// if they deny, they get logged out
</script>

<template>
  <div>
    <div
      class="fixed w-full h-full left-0 right-0 top-0 bottom-0 flex justify-center items-center z-fixed"
      v-if="user && !user.accepted_terms_at"
    >
      <div
        class="w-full h-full bg-black flex flex-col justify-center items-center relative p-4 pb-12 z-overlay bg-opacity-70"
      >
        <div
          class="relative bg-white rounded-lg p-4 md:p-8 m-4 max-h-screen max-w-full overflow-auto z-modal"
        >
          <div class="mb-4 font-bold">
            Please review our
            <router-link class="underline text-link" :to="{ name: 'TermsArcade' }" target="_blank"
              >updated Terms & Conditions</router-link
            >
          </div>
          <div class="mb-6">
            <p>The most important clarifications:</p>
            <ul>
              <li>You may not have more than 1 user account</li>
              <li>We may update games, points, and rewards at any time</li>
            </ul>
          </div>
          <div class="mb-4">
            <button class="btn" @click="acceptTerms">I accept the terms & conditions</button>
          </div>
          <div>
            <button @click="logout" class="btn btn-ch1red btn-sm">
              I do not accept the terms, log me out
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
