import { createStore } from "vuex";
import arcade from "./modules/arcade";
import auth from "./modules/auth";
import buy from "./modules/buy";
import poker from "./modules/poker";
import gallery from "./modules/gallery";
import gameDice from "./modules/gameDice";
import mint from "./modules/mint";
import suggestions from "./modules/suggestions";
import toasts from "./modules/toasts";
import users from "./modules/users";
// import websockets from "./modules/websockets";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    arcade,
    auth,
    buy,
    poker,
    gallery,
    gameDice,
    mint,
    suggestions,
    toasts,
    users,
    // websockets,
  },
});
