import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "cropperjs/dist/cropper.css";
import { setBaseUrl } from "./request";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

if (process.env.VUE_APP_API_URL) {
  setBaseUrl(process.env.VUE_APP_API_URL);
}

createApp(App).use(store).use(router).mount("#app");
