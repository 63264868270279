import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/portalgame",
    name: "PortalGame",
    // props: (route) => ({ token: route.query.token }),
    component: () => import(/* webpackChunkName: "test" */ "../views/Test.vue"),
    meta: {
      title: "Battle Lines",
    },
  },
  {
    path: "/cointo",
    name: "Cointo",
    // props: (route) => ({ token: route.query.token }),
    component: () => import(/* webpackChunkName: "cointo" */ "../views/Cointo.vue"),
    meta: {
      title: "Cointo",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/About.vue"),
  },
  {
    path: "/about",
    name: "About",
    redirect: { name: "Home" },
    // component: () => import(/* webpackChunkName: "home" */ "../views/About.vue"),
    // meta: {
    //   title: "About Society of the Hourglass",
    // },
  },
  {
    path: "/vision",
    name: "Vision",
    redirect: { name: "About" },
    // component: () => import(/* webpackChunkName: "home" */ "../views/Vision.vue"),
    // meta: {
    //   title: "Project Vision",
    // },
  },
  {
    path: "/book",
    name: "Book",
    redirect: { name: "About" },
    // component: () => import(/* webpackChunkName: "home" */ "../views/Book.vue"),
    // meta: {
    //   title: "Seek-n-Find Book",
    // },
  },
  {
    path: "/series",
    name: "Series",
    redirect: { name: "About" },
    // component: () => import(/* webpackChunkName: "home" */ "../views/Series.vue"),
    // meta: {
    //   title: "Animated Series",
    // },
  },
  {
    path: "/buy",
    name: "Buy",
    component: () => import(/* webpackChunkName: "buy" */ "../views/Buy.vue"),
    meta: {
      title: "Join the Society of the Hourglass",
    },
  },
  {
    path: "/buy/credit",
    name: "BuyCredit",
    component: () => import(/* webpackChunkName: "buy" */ "../views/BuyCredit.vue"),
    meta: {
      title: "Join the Society of the Hourglass",
    },
  },
  {
    path: "/buy/credit/success",
    name: "BuySuccess",
    component: () => import(/* webpackChunkName: "buy" */ "../views/BuySuccess.vue"),
    props: (route) => ({
      checkout_id: route.query.checkout_id,
      from_checkout: route.query.from_checkout,
    }),
    meta: {
      title: "Welcome to the Society of the Hourglass",
    },
  },
  {
    path: "/buy/mint",
    name: "Mint",
    component: () => import(/* webpackChunkName: "buy" */ "../views/Mint.vue"),
    meta: {
      title: "Mint Now & Join The Society",
    },
  },
  {
    path: "/arcade",
    name: "Arcade",
    component: () => import(/* webpackChunkName: "arcade" */ "../views/Arcade.vue"),
    meta: {
      title: "Arcade",
      // requiresAuth: true,
    },
  },
  {
    path: "/games",
    name: "Games",
    component: () => import(/* webpackChunkName: "arcade" */ "../views/GamesHome.vue"),
    meta: {
      title: "Games",
      // requiresAuth: true,
    },
  },
  {
    path: "/terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/Terms.vue"),
    meta: {
      title: "Terms & Conditions",
    },
    children: [
      {
        path: "",
        name: "TermsCollection",
        component: () => import(/* webpackChunkName: "terms" */ "../views/TermsCollection.vue"),
        meta: {
          title: "Terms & Conditions | NFT Collection",
        },
      },
      {
        path: "website",
        name: "TermsWebsite",
        component: () => import(/* webpackChunkName: "terms" */ "../views/TermsWebsite.vue"),
        meta: {
          title: "Terms & Conditions | Website",
        },
      },
      {
        path: "privacy",
        name: "TermsPrivacy",
        component: () => import(/* webpackChunkName: "terms" */ "../views/TermsPrivacy.vue"),
        meta: {
          title: "Privacy Policy",
        },
      },
      {
        path: "cookies",
        name: "TermsCookies",
        component: () => import(/* webpackChunkName: "terms" */ "../views/TermsCookies.vue"),
        meta: {
          title: "Cookie Policy",
        },
      },
      {
        path: "arcade",
        name: "TermsArcade",
        component: () => import(/* webpackChunkName: "terms" */ "../views/TermsArcade.vue"),
        meta: {
          title: "Terms & Conditions | Arcade",
        },
      },
      {
        path: "cointo",
        name: "TermsCointo",
        component: () => import(/* webpackChunkName: "terms" */ "../views/TermsCointo.vue"),
        meta: {
          title: "Privacy Policy | Cointo",
        },
      },
      {
        path: "/privacy/cointo",
        name: "PrivacyCointo",
        component: () => import(/* webpackChunkName: "terms" */ "../views/TermsCointo.vue"),
        meta: {
          title: "Privacy Policy | Cointo",
        },
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    // props: (route) => ({ token: route.query.token }),
    component: () => import(/* webpackChunkName: "auth" */ "../views/ForgotPassword.vue"),
    meta: {
      title: "Forgot Password",
      redirectAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    props: (route) => ({ from: route.query.from }),
    component: () => import(/* webpackChunkName: "auth" */ "../views/Login.vue"),
    meta: {
      title: "Login",
      redirectAuth: true,
    },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    props: (route) => ({ from: route.query.from }),
    component: () => import(/* webpackChunkName: "auth" */ "../views/SignUp.vue"),
    meta: {
      title: "Sign Up",
      redirectAuth: true,
    },
  },
  {
    path: "/login-token",
    name: "LoginToken",
    props: (route) => ({ token: route.query.token }),
    component: () => import(/* webpackChunkName: "auth" */ "../views/LoginToken.vue"),
  },
  {
    path: "/user-detail",
    name: "VerifyDetail",
    props: (route) => ({ verification: route.query.verification }),
    component: () => import(/* webpackChunkName: "auth" */ "../views/VerifyDetail.vue"),
  },
  {
    path: "/user-password",
    name: "ChangePassword",
    props: (route) => ({ verification: route.query.verification }),
    component: () => import(/* webpackChunkName: "auth" */ "../views/ChangePassword.vue"),
  },
  {
    path: "/user-detail-undo",
    name: "VerifyDetailUndo",
    props: (route) => ({ reversion: route.query.reversion }),
    component: () => import(/* webpackChunkName: "auth" */ "../views/VerifyDetailUndo.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Profile.vue"),
    meta: {
      title: "Profile",
      requiresAuth: true,
    },
  },
  // {
  //   path: "/forum",
  //   name: "Suggestions",
  //   component: () => import(/* webpackChunkName: "suggestions" */ "../views/SuggestionsHome.vue"),
  // },
  // {
  //   path: "/forum/topic/:id",
  //   name: "Suggestion",
  //   component: () => import(/* webpackChunkName: "suggestions" */ "../views/Suggestion.vue"),
  //   props: (route) => ({ id: route.params.id }),
  // },
  // {
  //   path: "/forum/:category",
  //   name: "Suggestions",
  //   component: () => import(/* webpackChunkName: "suggestions" */ "../views/SuggestionsCategory.vue"),
  // },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import(/* webpackChunkName: "gallery" */ "../views/Gallery.vue"),
    props: (route) => ({
      page: route.query.page,
      mine: route.query.mine,
      filters: route.query.filters,
      term: route.query.term,
      sort: route.query.sort,
    }),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/arcade/dice",
    name: "Dice",
    component: () => import(/* webpackChunkName: "dice" */ "../views/Dice.vue"),
    meta: {
      title: "Dice",
    },
  },
  {
    path: "/arcade/poker",
    name: "Poker",
    component: () => import(/* webpackChunkName: "poker" */ "../views/Poker.vue"),
    meta: {
      title: "Powerup Poker",
    },
  },
  {
    path: "/arcade/poker/standard",
    name: "PokerStandard",
    component: () => import(/* webpackChunkName: "poker" */ "../views/Poker.vue"),
    props: (route) => ({ freeplay: true }),
    meta: {
      title: "Standard Draw Poker",
    },
  },
  {
    path: "/arcade/trivia",
    name: "ArcadeTrivia",
    component: () => import(/* webpackChunkName: "trivia" */ "../views/DailyTrivia.vue"),
    meta: {
      title: "Daily Trivia",
    },
  },
  {
    path: "/arcade/portal",
    name: "PortalRedemption",
    component: () => import(/* webpackChunkName: "portal" */ "../views/PortalRedemption.vue"),
    meta: {
      title: "Portal Expeditions",
    },
  },
  {
    path: "/arcade/memory",
    name: "Memory",
    component: () => import(/* webpackChunkName: "memory" */ "../views/Memory.vue"),
    meta: {
      title: "Memory Match",
    },
  },
  {
    path: "/cabinet-of-curiosities",
    name: "Coc",
    component: () => import(/* webpackChunkName: "coc" */ "../views/Coc.vue"),
    meta: {
      title: "Cabinet of Curiosities Redemption Info",
    },
  },
  {
    path: "/cabinet-of-curiosities/assign-book",
    name: "CocAssignmentBook",
    component: () => import(/* webpackChunkName: "coc" */ "../views/CocAssignmentBook.vue"),
    meta: {
      title: "Cabinet of Curiosities Book Assignment",
    },
  },
  {
    path: "/cabinet-of-curiosities/assign-bot",
    name: "CocAssignmentBot",
    component: () => import(/* webpackChunkName: "coc" */ "../views/CocAssignmentBot.vue"),
    meta: {
      title: "Cabinet of Curiosities Bot Assignment",
    },
  },
  {
    path: "/cabinet-of-curiosities/redemption",
    name: "CocRedeem",
    component: () => import(/* webpackChunkName: "coc" */ "../views/CocRedeem.vue"),
    meta: {
      title: "Cabinet of Curiosities Redemptions",
    },
  },
  {
    path: "/user/:wallet",
    name: "UserDetail",
    component: () => import(/* webpackChunkName: "gallery" */ "../views/UserDetail.vue"),
    props: (route) => ({ wallet: route.params.wallet }),
    meta: {
      title: "Wallet Detail",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from) => {
  if (
    to.meta.requiresAuth &&
    !store.getters["auth/isLoggedIn"] &&
    store.getters["auth/isChecked"]
  ) {
    store.dispatch("toasts/add", {
      message: "Must be logged in for that page",
      variant: "error",
    });

    return {
      name: "Login",
      query: {
        from: to?.path ? encodeURI(to?.path) : null,
      },
    };
  }

  if (to?.meta?.redirectAuth && store.getters["auth"]) {
    return {
      name: "Profile",
    };
  }
});

router.afterEach((to, from) => {
  if (to?.meta?.title) {
    document.title = `${to.meta.title} | Society of the Hourglass`;
  } else {
    document.title = "Society of the Hourglass";
  }
});

export default router;
