<script setup>
import { onMounted, computed } from "vue";
import { setErrorResponseListener } from "./request";
import store from "./store";
import router from "./router";
import ToastService from "./components/ToastService.vue";
import { useRouter, useRoute } from "vue-router";
import TermsAcceptModal from "./components/TermsAcceptModal.vue";

const logoutUnauthorized = (error) => {
  console.info("response error", { error });
  if (store.getters["auth/isLoggedIn"] && error.response?.status === 401) {
    store.dispatch("logout");
  }

  if (error.response?.status === 429) {
    store.dispatch("toasts/add", {
      message: ["Too many requests!", "Wait a minute and try again"],
      variant: "error",
      timeout: 10000,
    });
  }
};

onMounted(async () => {
  setErrorResponseListener(logoutUnauthorized);
  await store.dispatch("auth/getUser");
});

const route = useRoute();
</script>

<template>
  <div class="flex flex-col h-full">
    <div class="flex-grow w-full">
      <router-view />
    </div>
  </div>
  <ToastService />
</template>

<style></style>
