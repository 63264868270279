// used for parsing server errors
class ErrorParse {
  list;

  fields;

  other;

  string;

  constructor(errors) {
    if (Array.isArray(errors)) {
      this.list = [...errors];
    }

    if (typeof errors === "object" && errors !== null) {
      this.fields = Object.keys(errors);
      this.list = this.fields.map((f) => {
        return errors[f];
      });
    } else {
      this.fields = [];
      this.list = [];
      this.other = errors;
    }

    this.string = this.list.join(", ");

    // console.info("Error class instantiantion", { errors, this: this });
  }

  toString() {
    return this.string;
  }
}

export default ErrorParse;
