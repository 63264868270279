import { ethers } from "ethers";
import { make } from "@/request";
import { markRaw } from "vue";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

export default {
  namespaced: true,
  state: {
    address: null,
    user: null,
    connection: null,
    checked: false,
  },
  mutations: {
    SET_ADDRESS(state, payload) {
      state.address = payload;
    },
    SET_USER(state, payload) {
      state.checked = true;
      state.user = payload;
    },
    SET_CONNECTION(state, payload) {
      state.connection = {
        signer: markRaw(payload?.signer),
        provider: markRaw(payload?.provider),
        address: payload?.address,
        network: markRaw(payload?.network),
      };
    },
    CLEAR_CONNECTION(state, payload) {
      state.connection = null;
    },
  },
  actions: {
    async emailSubscribe(context, email) {
      return await make({
        name: "emailSubscribe",
        data: {
          email,
        },
      });
    },
    async getUser({ commit }) {
      let request;
      try {
        request = await make({ name: "user" });
      } catch (e) {
        console.info("not logged in", { e });
        commit("SET_ADDRESS", null);
        commit("SET_USER", null);

        return false;
      }

      commit("SET_ADDRESS", request?.wallet_address);
      commit("SET_USER", request);

      return true;
    },
    async acceptTerms({ commit, dispatch }) {
      let request;
      try {
        request = await make({ name: "acceptTerms" });
      } catch (e) {
        console.info("not logged in", { e });
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      dispatch(
        "toasts/add",
        { message: "Terms & Conditions Accepted!", variant: "success" },
        { root: true }
      );

      commit("SET_ADDRESS", request?.wallet_address);
      commit("SET_USER", request);

      return true;
    },
    async loginToken({ commit, dispatch }, token) {
      try {
        await make({ name: "getCsrfCookie" });
      } catch (e) {
        console.info("couldn't get CSRF cookie", e);
      }

      let request;
      try {
        request = await make({
          name: "loginToken",
          data: {
            token,
          },
        });
      } catch (e) {
        console.info("not logged in from token", { e });
        commit("SET_ADDRESS", null);
        commit("SET_USER", null);

        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });

        throw e;
      }

      let userRequest;
      try {
        userRequest = await make({ name: "user" });
      } catch (e) {
        console.info("not logged in", { e });
        commit("SET_ADDRESS", null);
        commit("SET_USER", null);

        return;
      }

      dispatch("toasts/add", { message: "Logged In!", variant: "success" }, { root: true });

      commit("SET_ADDRESS", userRequest?.wallet_address);
      commit("SET_USER", userRequest);

      return;
    },
    async clearListeners({ state }) {
      if (state?.connection?.provider) {
        state.connection.provider.provider.removeAllListeners();
      }
      return;
    },
    async startListeners({ commit, dispatch, state }) {
      await dispatch("clearListeners");

      if (state?.connection?.provider) {
        state?.connection?.provider.provider.on("accountsChanged", (accounts) => {
          console.info("account changed: ", accounts);
          dispatch("connect", true);
        });

        // Subscribe to chainId change
        state?.connection?.provider.provider.on("chainChanged", (chainId) => {
          console.info("Chain changed: ", chainId);
          dispatch("connect", true);
        });

        // Subscribe to session disconnection
        state?.connection?.provider.provider.on("disconnect", (code, reason) => {
          console.info("Wallet disconnected: ", code, reason);
          commit("CLEAR_CONNECTION");
        });
      }

      return;
    },
    async connect({ commit, dispatch, state }, listenersOn) {
      await dispatch("clearListeners");

      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "18436e45322646beae413025603f5fe0", // required
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required
      });

      let signer, provider, network;
      try {
        const cleared = await web3Modal.clearCachedProvider();
        const instance = await web3Modal.connect();
        provider = new ethers.providers.Web3Provider(instance);
        signer = await provider.getSigner();
        network = await provider.getNetwork();
      } catch (e) {
        console.info("web3 rejected", { e });
        dispatch(
          "toasts/add",
          { message: "Web3 connection request rejected by user", variant: "error" },
          { root: true }
        );
        commit("CLEAR_CONNECTION");
        return false;
      }

      const address = await signer.getAddress();

      commit("SET_CONNECTION", { signer, provider, address, network });

      if (listenersOn) {
        dispatch("startListeners");
      }

      return { signer, provider, address, network };
    },
    async disconnect({ commit, dispatch }) {
      await dispatch("clearListeners");
      commit("CLEAR_CONNECTION");
      return;
    },
    async signup({ commit, dispatch, state }) {
      const connectRequest = await dispatch("connect");

      if (!connectRequest) {
        return;
      }

      let signature;
      try {
        signature = await connectRequest.signer.signMessage("Join the Society of the Hourglass");
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: "Web3 signature request rejected by user", variant: "error" },
          { root: true }
        );
        return;
      }

      try {
        const request = await make({
          name: "signupWallet",
          data: { address: connectRequest.address, signature },
        });
      } catch (e) {
        dispatch("toasts/add", { message: ["Login error", e], variant: "error" }, { root: true });
        return;
      }

      let loggedInCheck;
      try {
        loggedInCheck = await make({ name: "user" });
      } catch (e) {
        console.info("not logged in", { e });
        return;
      }

      commit("SET_ADDRESS", connectRequest.address);
      commit("SET_USER", loggedInCheck);

      dispatch("toasts/add", { message: "Logged In!", variant: "success" }, { root: true });

      return;
    },
    async signupPassword({ commit, dispatch }, { email, password }) {
      try {
        await make({ name: "getCsrfCookie" });
      } catch (e) {
        console.info("couldn't get CSRF cookie", e);
      }

      let request;
      try {
        request = await make({
          name: "signupPassword",
          data: { email, password },
        });
      } catch (e) {
        dispatch("toasts/add", { message: ["Login error", e], variant: "error" }, { root: true });
        return false;
      }

      dispatch(
        "toasts/add",
        { message: request, variant: "success", timeout: false },
        { root: true }
      );

      return true;
    },
    async verifyDetail({ commit, dispatch }, data) {
      try {
        await make({ name: "getCsrfCookie" });
      } catch (e) {
        console.info("couldn't get CSRF cookie", e);
      }

      let request;
      try {
        request = await make({
          name: "confirmUserDetail",
          data,
        });
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: ["Verification error", e], variant: "error" },
          { root: true }
        );
        return false;
      }

      dispatch(
        "toasts/add",
        { message: [request], variant: "success", timeout: 6000 },
        { root: true }
      );

      return request;
    },
    async undoVerifyDetail({ dispatch }, data) {
      try {
        await make({ name: "getCsrfCookie" });
      } catch (e) {
        console.info("couldn't get CSRF cookie", e);
      }

      let request;
      try {
        request = await make({
          name: "undoUserDetail",
          data,
        });
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: ["Reversion error", e], variant: "error" },
          { root: true }
        );
        return;
      }

      return request;
    },
    async triggerPasswordReset({ dispatch }, email) {
      let request;
      try {
        request = await make({
          name: "triggerPasswordReset",
          data: { email },
        });
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: ["Error triggering password reset", e], variant: "error" },
          { root: true }
        );
        return;
      }

      dispatch(
        "toasts/add",
        { message: [request], variant: "success", timeout: 6000 },
        { root: true }
      );

      return request;
    },
    async login({ commit, dispatch, state }) {
      try {
        await make({ name: "getCsrfCookie" });
      } catch (e) {
        console.info("couldn't get CSRF cookie", e);
      }

      if (state.address) {
        let loginCheck;
        try {
          loginCheck = await make({ name: "user" });
        } catch (e) {
          console.info("not logged in", { e });
          return;
        }

        commit("SET_ADDRESS", loginCheck?.wallet_address);
        commit("SET_USER", loginCheck);
        return;
      }

      const connectRequest = await dispatch("connect");

      if (!connectRequest) {
        return;
      }

      let signature;
      try {
        signature = await connectRequest.signer.signMessage("Enter the Society of the Hourglass");
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: "Web3 signature request rejected by user", variant: "error" },
          { root: true }
        );
        return;
      }

      try {
        const request = await make({
          name: "login",
          data: { address: connectRequest.address, signature },
        });
      } catch (e) {
        dispatch("toasts/add", { message: ["Login error", e], variant: "error" }, { root: true });
        return;
      }

      let loggedInCheck;
      try {
        loggedInCheck = await make({ name: "user" });
      } catch (e) {
        console.info("not logged in", { e });
        return;
      }

      commit("SET_ADDRESS", connectRequest.address);
      commit("SET_USER", loggedInCheck);

      dispatch("toasts/add", { message: "Logged In!", variant: "success" }, { root: true });

      return;
    },
    async loginPassword({ commit, dispatch, state }, { email, password }) {
      try {
        await make({ name: "getCsrfCookie" });
      } catch (e) {
        console.info("couldn't get CSRF cookie", e);
      }

      if (state.address) {
        let loginCheck;
        try {
          loginCheck = await make({ name: "user" });
        } catch (e) {
          console.info("not logged in", { e });
          return;
        }

        commit("SET_ADDRESS", loginCheck?.wallet_address);
        commit("SET_USER", loginCheck);
        return;
      }

      try {
        const request = await make({
          name: "loginPassword",
          data: { email, password },
        });
      } catch (e) {
        dispatch("toasts/add", { message: ["Login error", e], variant: "error" }, { root: true });
        return;
      }

      let loggedInCheck;
      try {
        loggedInCheck = await make({ name: "user" });
      } catch (e) {
        console.info("not logged in", { e });
        return;
      }

      commit("SET_USER", loggedInCheck);
      dispatch("toasts/add", { message: "Logged In!", variant: "success" }, { root: true });

      return;
    },
    async logout({ commit, dispatch }) {
      try {
        const request = await make({ name: "logout" });
      } catch (e) {
        // return e;
      }

      dispatch("poker/clearDay", null, { root: true });
      dispatch("toasts/add", { message: "Logged Out!", variant: "success" }, { root: true });

      commit("SET_ADDRESS", null);
      commit("SET_USER", null);
    },
    async authToken() {
      return await make({ name: "userAuthToken" });
    },
    async addWallet({ dispatch, commit }, data) {
      // $message = ;
      const connectRequest = await dispatch("connect");

      if (!connectRequest) {
        return;
      }

      let signature;
      try {
        signature = await connectRequest.signer.signMessage(
          "Society of the Hourglass: add a wallet"
        );
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: "Web3 signature request rejected by user", variant: "error" },
          { root: true }
        );
        return;
      }

      let request;
      try {
        request = await make({
          name: "userAddWallet",
          data: {
            address: connectRequest.address,
            signature,
          },
        });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      commit("SET_USER", request);

      dispatch("toasts/add", { message: "Wallet added!", variant: "success" }, { root: true });

      return true;
    },
    async removeWallet({ dispatch, commit }, data) {
      let request;
      try {
        request = await make({ name: "userRemoveWallet", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      commit("SET_USER", request);

      dispatch("toasts/add", { message: "Wallet removed!", variant: "success" }, { root: true });

      return true;
    },
    async makePrimaryWallet({ dispatch, commit }, data) {
      let request;
      try {
        request = await make({ name: "userPrimaryWallet", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      commit("SET_USER", request);

      dispatch(
        "toasts/add",
        { message: "Primary wallet update!", variant: "success" },
        { root: true }
      );

      return true;
    },
    async updateUsername({ dispatch, commit }, data) {
      let request;
      try {
        request = await make({ name: "userUpdateName", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      commit("SET_USER", request);

      dispatch("toasts/add", { message: "Username updated!", variant: "success" }, { root: true });

      return true;
    },
    async updatePassword({ dispatch, commit }, data) {
      let request;
      try {
        request = await make({ name: "updatePassword", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      dispatch("toasts/add", { message: "Password updated!", variant: "success" }, { root: true });

      return true;
    },
    async updateEmail({ dispatch, commit }, data) {
      let request;
      try {
        request = await make({ name: "updateEmail", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      dispatch("toasts/add", { message: request, variant: "success" }, { root: true });

      return true;
    },
    async initialSetEmailPassword({ dispatch }, data) {
      let request;
      try {
        request = await make({ name: "initialSetEmailPassword", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      dispatch(
        "toasts/add",
        { message: request, variant: "success", timeout: 10000 },
        { root: true }
      );

      return true;
    },
    async getReversionSummary({ dispatch }, params) {
      let request;
      try {
        request = await make({ name: "getReversionSummary", params });
      } catch (e) {
        dispatch(
          "toasts/add",
          { message: ["Could not get reversion data", e], variant: "error" },
          { root: true }
        );
        return false;
      }

      return request;
    },
  },
  getters: {
    isChecked(state) {
      return !!state?.checked;
    },
    isLoggedIn(state) {
      return !!state?.user;
    },
    tokenOwnedByUser: (state) => (token) => {
      return !!state.user?.wallets.find((w) => {
        return w.wallet_address === token?.owner_address;
      });
    },
  },
};
