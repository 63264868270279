import { make } from "@/request";

export default {
  namespaced: true,
  state: {
    list: [],
    item: null,
    createEdit: null,
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload ? payload : [];
    },
    SET_SHOW_CREATE_EDIT(state, payload) {
      state.createEdit = payload;
    },
  },
  actions: {
    setShowCreateEdit({ commit }, payload) {
      commit("SET_SHOW_CREATE_EDIT", payload);
    },
    async getList({ commit }, data) {
      return await make({ name: "galleryGet", params: data });
    },
    async getFilters(obj, data) {
      return await make({ name: "galleryGetFilters", params: data });
    },
    async updateToken({ dispatch }, data) {
      let request;
      try {
        request = await make({ name: "galleryUpdate", data });
      } catch (e) {
        dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
        return false;
      }

      dispatch("toasts/add", { message: request, variant: "success" }, { root: true });

      return true;
    },
  },
};
