import { make } from "@/request";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getTokens({ commit }, wallet) {
      return await make({ name: "walletTokensGet", params: { wallet } });
    },
    async getTokensByWallet({ commit }, wallet) {
      return await make({ name: "walletTokensByWalletGet", params: { wallet } });
    },
    async redemptionAddressCreate(context, data) {
      return await make({
        name: "redemptionAddressCreate",
        data,
      });
    },
    async refreshTokenBalances() {
      return await make({
        name: "walletTokensRefreshBalances",
      });
    },
  },
};
