import RequestDefinition from "../classes/RequestDefinition";

export const getCsrfCookie = new RequestDefinition({
  path: "sanctum/csrf-cookie",
  method: "get",
});

export const login = new RequestDefinition({
  path: "login",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const loginPassword = new RequestDefinition({
  path: "login-password",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const loginToken = new RequestDefinition({
  path: "login-token",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const signupWallet = new RequestDefinition({
  path: "signup-wallet",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const signupPassword = new RequestDefinition({
  path: "signup-password",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const logout = new RequestDefinition({
  path: "logout",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

// API routes

export const acceptTerms = new RequestDefinition({
  path: "api/accept-terms",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const confirmUserDetail = new RequestDefinition({
  path: "api/user-detail",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const undoUserDetail = new RequestDefinition({
  path: "api/user-detail/undo",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const triggerPasswordReset = new RequestDefinition({
  path: "api/user-password",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const updatePassword = new RequestDefinition({
  path: "api/change-password",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const updateEmail = new RequestDefinition({
  path: "api/change-email",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const initialSetEmailPassword = new RequestDefinition({
  path: "api/email-password",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const getReversionSummary = new RequestDefinition({
  path: "api/change-request",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.change;
  },
});

export const user = new RequestDefinition({
  path: "api/user",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const userAuthToken = new RequestDefinition({
  path: "api/auth-token",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const pokerGetDay = new RequestDefinition({
  path: "api/arcade/poker/day",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const pokerCreateDay = new RequestDefinition({
  path: "api/arcade/poker/day",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const pokerLockDay = new RequestDefinition({
  path: "api/arcade/poker/day",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const pokerResetDay = new RequestDefinition({
  path: "api/arcade/poker/day/reset",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const pokerGetTokens = new RequestDefinition({
  path: "api/arcade/poker/tokens",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tokens ?? null;
  },
});

export const pokerStartGame = new RequestDefinition({
  path: "api/arcade/poker/game",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const pokerGameDraw = new RequestDefinition({
  path: "api/arcade/poker/game",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const pokerGamePowerup = new RequestDefinition({
  path: "api/arcade/poker/game/powerup",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const pokerLeaderboard = new RequestDefinition({
  path: "api/arcade/poker/leaderboard",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const pokerTempGameGet = new RequestDefinition({
  path: "api/arcade/poker/temp-game",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const pokerTempGameStart = new RequestDefinition({
  path: "api/arcade/poker/temp-game",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const pokerTempGameDraw = new RequestDefinition({
  path: "api/arcade/poker/temp-game",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const galleryGet = new RequestDefinition({
  path: "api/gallery",
  method: "get",
  transformResponse: (response) => {
    return {
      tokens: response.data?.content?.tokens ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters,
      total_items: response.data?.content?.total_items,
    };
  },
});

export const galleryGetFilters = new RequestDefinition({
  path: "api/gallery/filters",
  method: "get",
  transformResponse: (response) => {
    return response?.data?.content;
  },
});

export const galleryUpdate = new RequestDefinition({
  path: "api/gallery/update",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const walletTokensGet = new RequestDefinition({
  path: "api/wallet-tokens/{wallet}",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const walletTokensByWalletGet = new RequestDefinition({
  path: "api/wallet-tokens/{wallet}/simple",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tokens ?? null;
  },
});

export const walletTokensRefreshBalances = new RequestDefinition({
  path: "api/wallet-tokens/balances",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const userAddWallet = new RequestDefinition({
  path: "api/add-wallet",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const userPrimaryWallet = new RequestDefinition({
  path: "api/primary-wallet",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const userRemoveWallet = new RequestDefinition({
  path: "api/remove-wallet",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const userUpdateName = new RequestDefinition({
  path: "api/username",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const emailSubscribe = new RequestDefinition({
  path: "api/email-subscribe",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const getCountries = new RequestDefinition({
  path: "api/countries",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.conutries ?? null;
  },
});

export const redemptionAddressCreate = new RequestDefinition({
  path: "api/redemption/address",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.redemption_address ?? null;
  },
});

export const arcadeRedeemChips = new RequestDefinition({
  path: "api/arcade/chips",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const arcadeRedeemPoints = new RequestDefinition({
  path: "api/arcade/points",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const arcadeRedeemMedallions = new RequestDefinition({
  path: "api/arcade/medallions",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const portalInfo = new RequestDefinition({
  path: "api/arcade/portal/info",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.expeditions ?? null;
  },
});

export const portalRedemptionCredit = new RequestDefinition({
  path: "api/arcade/portal/credits",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const arcadeTriviaDaily = new RequestDefinition({
  path: "api/arcade/trivia",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const arcadeTriviaDailyAnswer = new RequestDefinition({
  path: "api/arcade/trivia",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const arcadeTriviaDailyReport = new RequestDefinition({
  path: "api/arcade/trivia/report",
  method: "post",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const arcadeMemoryTokens = new RequestDefinition({
  path: "api/arcade/memory/tokens",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tokens ?? null;
  },
});

export const arcadeMemoryDaily = new RequestDefinition({
  path: "api/arcade/memory/daily",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const suggestionsGet = new RequestDefinition({
  path: "suggestions",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.suggestions ?? null;
  },
});

export const suggestionGet = new RequestDefinition({
  path: "suggestions/{id}",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionMake = new RequestDefinition({
  path: "suggestions",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionUpdate = new RequestDefinition({
  path: "suggestions/{id}",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionDelete = new RequestDefinition({
  path: "suggestions/{id}",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const suggestionGetComments = new RequestDefinition({
  path: "suggestions/{id}/comments",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionCommentMake = new RequestDefinition({
  path: "comments",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.comment ?? null;
  },
});

export const suggestionCommentUpdate = new RequestDefinition({
  path: "comments/{id}",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.comment ?? null;
  },
});

export const suggestionCommentDelete = new RequestDefinition({
  path: "comments/{id}",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const suggestionVoteMake = new RequestDefinition({
  path: "votes",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.vote ?? null;
  },
});

export const suggestionVoteDelete = new RequestDefinition({
  path: "votes/{id}",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.successes ?? null;
  },
});

export const buyEstimate = new RequestDefinition({
  path: "api/buy/estimate",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.estimate ?? null;
  },
});

export const buyCheckout = new RequestDefinition({
  path: "api/buy/checkout",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.order ?? null;
  },
});

export const buyDetails = new RequestDefinition({
  path: "api/buy/details",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.order ?? null;
  },
});

export const buyOrders = new RequestDefinition({
  path: "api/buy/orders",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.orders ?? null;
  },
});
