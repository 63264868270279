import { isArray, isObject } from "lodash-es";

class Toast {
  constructor({ id, timeout, message, title, variant, dismissible } = {}) {
    this.timeout = timeout === false ? false : timeout ?? (variant === "error" ? 8000 : 5000);
    this.messages = isArray(message) ? message : [message];

    if (this.messages?.length) {
      let newMsgArray = [];
      this.messages?.forEach((me) => {
        if (isArray(me)) {
          newMsgArray = [...newMsgArray, ...me];
        } else if (isObject(me)) {
          let keys = Object.keys(me);
          keys.forEach((k) => {
            if (isArray(me[k])) {
              me[k]?.forEach((m) => {
                newMsgArray = [...newMsgArray, m];
              });
            } else {
              newMsgArray = [...newMsgArray, me[k]];
            }
          });
        } else {
          newMsgArray = [...newMsgArray, me];
        }
      });

      this.messages = [...newMsgArray];
    }

    this.id = id;
    this.title = title;
    this.variant = variant ?? "default";
    this.dismissible = dismissible === false ? false : true;
  }
}

export default Toast;
