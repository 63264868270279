import axios from "axios";
import * as definitions from "./definitions";
import RequestDefinition from "./classes/RequestDefinition";
import RequestGroupDefinition from "./classes/RequestGroupDefinition";

axios.defaults.withCredentials = true;

const buildUrl = (url = null, params = null, base = "/", insecure = false) => {
  const relative = base.substr(0, 4) !== "http";
  let string = `${base}/${url || ""}`;
  string = string.replace(new RegExp(/^(http|https):\/\//, "g"), "");

  const data = { ...params };
  const usedKeys = [];

  if (params && typeof params === "object") {
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i += 1) {
      if (string.indexOf(`{${keys[i]}}`) >= 0) {
        usedKeys.push(keys[i]);
      }
      const re = new RegExp(`\\{${keys[i]}\\}`, "g");
      string = string.replace(re, params[keys[i]]);
    }
  }

  usedKeys.forEach((k) => {
    delete data[k];
  });

  string = string.replace(new RegExp(/\/{2,3}/, "g"), "/");

  if (!relative) {
    string = insecure ? `http://${string}` : `https://${string}`;
  }

  const lastLetter = string.substr(string.length - 1);

  if (lastLetter === "/") {
    string = string.slice(0, -1);
  }

  return { url: string, params: data };
};

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getCancelToken = () => {
  const { CancelToken } = axios;
  return CancelToken;
};

const mergeHeaders = (inputHeaders) => ({ ...defaultHeaders, ...inputHeaders });

export const setAuthResponseListener = (callback) => {
  axios.interceptors.response.use(
    (response) => {
      const authToken = response?.data?.token;
      if (authToken) {
        callback(authToken);
      }
      return response;
    },
    (error) => Promise.reject(error)
  );
};

export const setErrorResponseListener = (callback) => {
  console.info("Request err handling called", { callback });
  axios.interceptors.response.use(null, (error) => {
    callback(error);
    return Promise.reject(error);
  });
};

export const setAuthRequestHeader = (token) =>
  axios.interceptors.request.use(
    (config) => {
      const newConfig = { ...config };
      newConfig.headers.Authorization = `Bearer ${token}`;
      return newConfig;
    },
    (error) => Promise.reject(error)
  );

export const ejectInterceptor = (interceptor) => {
  axios.interceptors.request.eject(interceptor);
};

export const setBaseUrl = (base) => {
  axios.defaults.baseURL = base;
  return true;
};

/**
 * @desc build a request object compatible with axios
 * @param config object
 * @return Promise - axios Promise
 */
const prepRequest = (config) => {
  const { path, method, params = {}, data = {}, options = {}, base = "/" } = config;

  if (!base || !path || !method) {
    throw new Error("Requests must have a base, path, and method defined");
  }

  const urlAndData = buildUrl(path, params, base);

  const requestObj = {
    method,
    url: urlAndData.url,
    data,
    responseType: "json",
    headers: defaultHeaders,
    ...options,
  };

  if (options && options.headers) {
    requestObj.headers = mergeHeaders(options.headers);
  }

  requestObj.params = urlAndData.params;

  return requestObj;
};

/**
 * @desc directly send a request using our chosen http handler
 * @param request object - the request with all options
 * @return Promise - axios Promise
 */
export const direct = (request) => axios.request(request);

/**
 * @desc lookup and return the definition based on the name
 * @param name - string: the name of the definition
 * @return definition - Definition/GroupDefinition instance
 */
const getDefinition = (name) => {
  const definition = definitions[name];
  if (!name || !definition) {
    throw new Error(`No valid request definition found for request "${name}"`);
  }

  if (!(definition instanceof RequestDefinition || definition instanceof RequestGroupDefinition)) {
    throw new Error(
      "The definition must be one of the following classes: Definition or GroupDefinition"
    );
  }

  return definition;
};

/**
 * @desc make a request with a defined route
 * @param config object - config object
 * @return Promise - axios Promise
 */
const makeRequest = ({ name, params = {}, data = {}, options = {}, base = "/" }) => {
  const definition = getDefinition(name);

  if (definition instanceof RequestGroupDefinition) {
    const requests = [];
    definition.definitions.forEach((def) => {
      requests.push(
        axios
          .request(
            prepRequest({
              path: def.path,
              method: def.method,
              params: def.transformParams(params),
              data: def.transformRequest(data),
              options: { ...def.options, ...options, credentials: true },
              base,
            })
          )
          .catch((e) => Promise.reject(def.transformError(e)))
      );
    });
    return axios.all(requests);
  }

  let headers = definition.headers || {};
  if (options && options.headers) {
    headers = { ...headers, ...options.headers };
  }

  const request = prepRequest({
    path: definition.path,
    method: definition.method,
    params: definition.transformParams(params),
    data: definition.transformRequest(data),
    options: { ...definition.options, ...options, headers, credentials: true },
    base,
  });

  return axios.request(request).catch((e) => Promise.reject(definition.transformError(e)));
};

const makeRequestList = (list) => {
  const requests = [];
  list.forEach((item) => {
    const definition = getDefinition(item.name);

    requests.push(
      axios
        .request(
          prepRequest({
            path: definition.path,
            method: definition.method,
            params: definition.transformParams(item.params || {}),
            data: definition.transformRequest(item.data || {}),
            options: { ...definition.options, ...(item.options || {}), credentials: true },
            base: item.base || "/",
          })
        )
        .catch((e) => Promise.reject(definition.transformError(e)))
    );
  });

  return axios.all(requests);
};

/**
 * @desc make a request with a defined route, and also parse the request
 * @param config object - config object
 * @return Promise - axios Promise
 */
export const make = ({ name, params = {}, data = {}, options = {}, base = "/" }) => {
  const definition = getDefinition(name);

  return new Promise((resolve, reject) => {
    makeRequest({ name, params, data, options, base })
      .then((response) => {
        if (definition.transformResponse) {
          let parsedResponse;
          if (definition instanceof RequestGroupDefinition) {
            parsedResponse = definition.transformResponse(...response);
          } else {
            parsedResponse = definition.transformResponse(response);
          }
          resolve(parsedResponse);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeList = (list) => {
  const definitionList = [];
  list.forEach((item) => definitionList.push(getDefinition(item.name)));

  return new Promise((resolve, reject) => {
    makeRequestList(list)
      .then((responseList) => {
        const formattedResponse = [];
        responseList.forEach((response, index) => {
          const definition = definitionList[index];
          if (definition.transformResponse) {
            let parsedResponse;
            if (definition instanceof RequestGroupDefinition) {
              parsedResponse = definition.transformResponse(...response);
            } else {
              parsedResponse = definition.transformResponse(response);
            }
            formattedResponse.push(parsedResponse);
          } else {
            formattedResponse.push(response);
          }
        });
        resolve(formattedResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeDownload = (
  name,
  params = {},
  data = {},
  options = {},
  base = "/",
  fileName = "download"
) => {
  const definition = getDefinition(name);
  const request = prepRequest(definition.path, definition.method, params, data, options, base);

  return new Promise((resolve, reject) => {
    axios
      .get(request.url, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: response?.headers?.["content-type"] });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        resolve("downloaded");
      })
      .catch((error) => {
        reject(error);
      });
  });
};
